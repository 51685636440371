import React from "react";
import {
  SEO,
  PageLayout,
  Content,
  Image,
  InternalLink,
  FishermanIcon,
  Accordion,
  MultipleComponentIterator,
  SlideReveal,
  MarkdownContent,
  MultipleComponentIteratorMap,
  PriceRange,
  FadeReveal,
  LocationName,
  Location,
  HoursOfOperation,
  BasicCarousel,
  componentIterator,
  multipleComponentIterator,
  ReviewV2,
  CustomerReviewModal,
  FormattedDate,
  BlogPostLink,
} from "@bluefin/components";
import { Grid, Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
  withArgs,
} from "../utils/utils";
import {
  createServiceCategorySlug,
  createServiceItemSlug,
} from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessMenuCategory,
      allFishermanBusinessMenuItem,
      allFishermanBusinessLocation,
      allFishermanBusinessBlogPost,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout hero={false} subfooter={false} className={"home-page"}>
          <Grid
            className={"default-hero"}
            stackable={true}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <Header as={"h1"} className={""}>
                <span
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "home_hero_header",
                  })}
                />
                <span
                  className={"highlighted"}
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "home_hero_header_highlighted",
                    defaultValue: "Home",
                  })}
                />
              </Header>
              <Content textAlign={"center"}>
                <Content.Markup
                  width={8}
                  isMarkdown={true}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "home_hero_description",
                  })}
                  extra={<div className={"ctas-container"} />}
                />
              </Content>
              <Image
                size={"large"}
                centered={true}
                src={
                  "https://fisherman.gumlet.io/public/d34fef6f-0e8d-4931-8d6b-822f2f1f12b3/Michael_Perry_Logo_acghpV0.png"
                }
              />
              <Button
                id={"navigate-down-arrow"}
                className={"navigate-down-arrow"}
                to={"#navigate-down-arrow"}
                as={InternalLink}
                icon={true}
                aria-label={"Scroll Down to Next Section"}
              >
                <FishermanIcon iconName={"arrow-down"} />
              </Button>
            </Grid.Column>
          </Grid>
          <div id={"services-section"} className={"services-section"}>
            <Accordion exclusive={true} className={"services-accordion"}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessMenuCategory.nodes}
                limit={6}
                components={[
                  {
                    component: <React.Fragment />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: <Accordion.Title />,
                        propMap: { index: "__itemIndex__" },
                        children: [
                          {
                            component: (
                              <SlideReveal
                                fade={true}
                                direction={"right"}
                                firstAnimation={{ direction: "up" }}
                              />
                            ),
                            children: [
                              {
                                component: <Header as={"h2"} />,
                                children: [
                                  {
                                    component: (
                                      <span children={{ name: "name" }} />
                                    ),
                                    propMap: { children: "name" },
                                  },
                                  {
                                    component: (
                                      <FishermanIcon iconName={"plus"} />
                                    ),
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                      {
                        component: <Accordion.Content />,
                        propMap: { index: "__itemIndex__" },
                        children: [
                          {
                            component: <MarkdownContent />,
                            propMap: { content: "description" },
                          },
                          {
                            component: (
                              <Button
                                primary={true}
                                content={getComponentContentNodeContent({
                                  components:
                                    fishermanBusinessWebsitePage.components,
                                  componentId:
                                    "service_category_details_button",
                                  defaultValue: "View Details",
                                  enforceDefault: true,
                                })}
                                event={{
                                  category: "Services",
                                  action: "View Category Details",
                                }}
                                as={InternalLink}
                              />
                            ),
                            propMap: {
                              "event.label": "name",
                              to: createServiceCategorySlug,
                            },
                          },
                          {
                            component: (
                              <Grid
                                className={"service-category-items"}
                                doubling={true}
                                stackable={true}
                                columns={2}
                              />
                            ),
                            children: [
                              {
                                component: (
                                  <MultipleComponentIteratorMap
                                    lookupField={"_id"}
                                    iterator={
                                      allFishermanBusinessMenuItem.nodes
                                    }
                                    components={[
                                      {
                                        component: <Grid.Column />,
                                        children: [
                                          {
                                            component: (
                                              <InternalLink
                                                event={{
                                                  category: "Services",
                                                  action: "View Item Details",
                                                }}
                                              />
                                            ),
                                            propMap: {
                                              "event.label": "name",
                                              to: withArgs({
                                                func: createServiceItemSlug,
                                                args: [
                                                  allFishermanBusinessMenuCategory.nodes,
                                                ],
                                              }),
                                            },
                                            children: [
                                              {
                                                component: <Header as={"h3"} />,
                                                propMap: { children: "name" },
                                              },
                                              {
                                                component: (
                                                  <PriceRange
                                                    priceKey={"price"}
                                                    settings={{
                                                      displayType: "range",
                                                      displayZeroCost: false,
                                                    }}
                                                  />
                                                ),
                                                propMap: {
                                                  prices: "variations",
                                                },
                                              },
                                              {
                                                component: (
                                                  <FishermanIcon
                                                    iconName={"arrow-right"}
                                                  />
                                                ),
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ]}
                                  />
                                ),
                                propMap: { targetValues: "items" },
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Accordion>
          </div>
          <div className={"locations-info-section"}>
            <Grid stackable={true} doubling={true} columns={2}>
              <Grid.Column width={9} className={"markdown-column"}>
                <FadeReveal duration={1250} triggerOnce={true}>
                  <Header
                    as={"h2"}
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "home_content_header",
                    })}
                  />
                  <MarkdownContent
                    content={getComponentContentNodeContent({
                      components: fishermanBusinessWebsitePage.components,
                      componentId: "home_section_description",
                    })}
                  />
                </FadeReveal>
              </Grid.Column>
              <Grid.Column width={7} className={"locations-column"}>
                <FadeReveal duration={1250} triggerOnce={true} delay={450}>
                  <Accordion
                    className={"locations-accordion"}
                    exclusive={true}
                    defaultActiveIndexes={[0]}
                  >
                    <MultipleComponentIterator
                      iterator={allFishermanBusinessLocation.nodes}
                      components={[
                        {
                          component: <React.Fragment />,
                          propMap: { key: "_id" },
                          children: [
                            {
                              component: (
                                <Accordion.Title
                                  activeIcon={"minus"}
                                  inactiveIcon={"plus"}
                                />
                              ),
                              propMap: { index: "__itemIndex__" },
                              children: [
                                {
                                  component: <LocationName />,
                                  propMap: {
                                    locationName: "name",
                                    "address.city": "city",
                                    "address.state": "state",
                                  },
                                },
                              ],
                            },
                            {
                              component: <Accordion.Content />,
                              propMap: { index: "__itemIndex__" },
                              children: [
                                {
                                  component: (
                                    <Location
                                      businessName={fishermanBusiness.name}
                                      showBusinessName={false}
                                    />
                                  ),
                                  propMap: {
                                    "address.street": "street",
                                    "address.city": "city",
                                    "address.state": "state",
                                    "address.zip": "zipCode",
                                  },
                                },
                                {
                                  component: (
                                    <HoursOfOperation
                                      displayOption={"grouped"}
                                    />
                                  ),
                                  propMap: {
                                    hours: "hours",
                                    timezone: "timezone",
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ]}
                    />
                  </Accordion>
                </FadeReveal>
              </Grid.Column>
            </Grid>
          </div>
          <div className={"image-gallery-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_gallery_header",
                defaultValue: "Gallery",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "image_gallery_description",
              })}
            />
            <BasicCarousel
              className={"image-gallery stretched-carousel"}
              size={5}
              displayIndicators={false}
              displayPlayPauseButton={false}
              autoScroll={false}
              tablet={{}}
              mobile={{}}
              animation={{
                name: "blur",
                duration: 200,
                transitionProps: {
                  preAnimating: { opacity: 0.99, blur: "2px" },
                  animating: { opacity: 0.99, blur: "2px" },
                },
              }}
              items={componentIterator({
                iterator: getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "ImageCollection",
                  componentIdentifier: "image_gallery",
                }),
                component: (
                  <Image background={true} className={"gallery-image-item"} />
                ),
                propMap: { src: "__all__" },
              })}
            />
            <Grid className={"trusted-by-grid"} stackable={true} columns={2}>
              <Grid.Column width={5} className={"copy-column"}>
                <Header
                  as={"h2"}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "trusted_by_header",
                    defaultValue: "Trusted By:",
                  })}
                />
              </Grid.Column>
              <Grid.Column width={11} className={"images-column"}>
                <BasicCarousel
                  className={"image-gallery regular-carousel"}
                  size={4}
                  displayIndicators={false}
                  displayPlayPauseButton={true}
                  autoScroll={true}
                  animation={{
                    name: "blur",
                    duration: 200,
                    transitionProps: {
                      preAnimating: { opacity: 0.99, blur: "2px" },
                      animating: { opacity: 0.99, blur: "2px" },
                    },
                  }}
                  mobile={{ size: 2 }}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                      componentIdentifier: "trusted_by_image_gallery",
                    }),
                    component: (
                      <Image
                        background={true}
                        className={"gallery-image-item"}
                      />
                    ),
                    propMap: { src: "__all__" },
                  })}
                />
              </Grid.Column>
            </Grid>
          </div>
          <div className={"reviews-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "reviews_section_header",
                defaultValue: "Notes From Our Visitors",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "reviews_section_description",
              })}
            />
            <BasicCarousel
              className={"reviews-carousel faded-sides-carousel"}
              size={5}
              displayIndicators={false}
              stepSize={1}
              autoScroll={false}
              displayPlayPauseButton={false}
              animation={{ name: "blur", duration: 160 }}
              items={multipleComponentIterator({
                iterator: fishermanBusiness.reviews,
                components: [
                  {
                    component: <ReviewV2 />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <ReviewV2.Text
                            charLimit={280}
                            seeMoreIcon={"plus"}
                            seeLessIcon={"minus"}
                          />
                        ),
                        propMap: { text: "text" },
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Rating />,
                            propMap: { rating: "rating" },
                          },
                          {
                            component: <ReviewV2.RatingLabel />,
                            propMap: { rating: "rating" },
                          },
                        ],
                      },
                      {
                        component: <ReviewV2.Line />,
                        children: [
                          {
                            component: <ReviewV2.Author />,
                            propMap: { author: "author" },
                          },
                          {
                            component: <ReviewV2.Source />,
                            propMap: { source: "source", link: "link" },
                          },
                        ],
                      },
                    ],
                  },
                ],
              })}
            />
            <div className={"submit-review-button-container"}>
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={fishermanBusiness.type}
                buttonText={getComponentContentNodeContent({
                  components: fishermanBusinessWebsitePage.components,
                  componentId: "submit_review_button",
                  defaultValue: "Submit Review",
                  enforceDefault: true,
                })}
              />
            </div>
          </div>
          <div className={"blogs-section"}>
            <Header
              as={"h2"}
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_header",
                defaultValue: "",
              })}
            />
            <MarkdownContent
              content={getComponentContentNodeContent({
                components: fishermanBusinessWebsitePage.components,
                componentId: "blog_section_description",
              })}
            />
            <Card.Group
              className={"featured-blogs"}
              itemsPerRow={2}
              stackable={true}
            >
              <MultipleComponentIterator
                iterator={allFishermanBusinessBlogPost.nodes}
                limit={2}
                components={[
                  {
                    component: <Card />,
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: <Card.Content />,
                        children: [
                          {
                            component: (
                              <div className={"see-our-blog-container"} />
                            ),
                            children: [
                              {
                                component: (
                                  <Button
                                    to={"/blog/"}
                                    as={InternalLink}
                                    event={{
                                      category: "Blog",
                                      action: "View All Blog Posts",
                                    }}
                                    content={"Blog"}
                                  />
                                ),
                              },
                            ],
                          },
                          {
                            component: <Card.Header className={"title"} />,
                            propMap: { content: "title" },
                          },
                          {
                            component: <FormattedDate outputFormat={"PPP"} />,
                            propMap: { date: "publishedAt" },
                          },
                          {
                            component: (
                              <MarkdownContent className={"summary"} />
                            ),
                            propMap: { content: "summary" },
                          },
                          {
                            component: (
                              <BlogPostLink
                                content={
                                  <div>
                                    <span>READ</span>
                                    <FishermanIcon iconName={"arrow-right"} />
                                  </div>
                                }
                              />
                            ),
                            propMap: {
                              key: "_id",
                              slug: "slug",
                              title: "title",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        _id
        author
        text
        source
        rating
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
    allFishermanBusinessMenuCategory(
      sort: { fields: [isFeatured, order], order: [DESC, ASC] }
    ) {
      nodes {
        description
        name
        _id
        items
      }
    }
    allFishermanBusinessMenuItem(
      sort: { fields: [order], order: [ASC] }
      filter: { visible: { eq: true } }
    ) {
      nodes {
        _id
        name
        description
        image
        visible
        available
        files
        variations {
          price
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        state
        zipCode
        name
        hours {
          open
          close
          day
          label
        }
        timezone
        phoneNumber
        primaryLocation
      }
    }
    allFishermanBusinessBlogPost(filter: { destination: { eq: "BLOG" } }) {
      nodes {
        _id
        title
        slug
        author {
          firstName
          lastName
          avatar
          email
        }
        summary
        content
        heroImage
        gatsbyHeroImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        publishedAt
      }
    }
  }
`;
